import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home/Home.vue';
import Form from '../views/Form/Form.vue';
import ThankYou from '../views/ThankYou/ThankYou.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    // {
    //     path: '/formulaire',
    //     name: 'Form',
    //     component: Form,
    // },
    // {
    //     path: '/Merci',
    //     name: 'ThankYou',
    //     component: ThankYou,
    // },
    {
        path: '*',
        redirect: { name: 'Home' },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

export default router;
