










import Header from '@/views/Home/Header.vue';
import Main from '@/views/Home/Main.vue';
import Specialties from '@/views/Home/Specialties.vue';
import Partners from '@/views/Home/Partners.vue';
import Footer from '@/views/Home/Footer.vue';
export default {
    name: 'Home',
    components: {
        Header,
        Main,
        Specialties,
        Partners,
        Footer,
    },
};
