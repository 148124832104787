






















import Vue from 'vue';

export default Vue.extend({
    name: 'Header',
    methods: {
        goToHome() {
            if (this.$route.name != 'Home') {
                this.$router.push({ name: 'Home' }).catch(() => {});
            }
        },
    },
});
