




































































































































































































































































































































































































































































import Vue from 'vue';
import Modal from './components/Modal.vue';

export default Vue.extend({
    name: 'Footer',
    components: {
        Modal,
    },
    data() {
        return {
            showModalLegalNotice: false,
            showModalPrivacyPolicy: false,
            showModalDataProtection: false,
        };
    },
});
